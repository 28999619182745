<template>
  <div>
    <validation-observer
      v-if="$route.name == ruta"
      ref="observer"
      v-slot="{ validate }"
      class="w-full text-left"
    >
      <div class="columns">
        <div class="column is-3" v-if="$screen.width > 768">
          <banner-left
            :pasiva="getLinea.pasiva ? true : false"
            :image="image"
            :titulo="'Cuentas por ' + (getLinea.pasiva ? 'pagar' : 'cobrar')"
            texto="Una cuenta por cobrar es la deuda a crédito que un cliente 
          contrae con su proveedor por la adquisición de un bien o servicio.
           La deuda puede ser a corto o a largo plazo."
          />
        </div>
        <div class="column is-9 text-left" v-if="getCostosExtras.length > 0">
          <div class="columns">
            <div class="column is-12 text-left">
              <p class="text-2xl font-bold">
                Agregar cuenta por {{ getLinea.pasiva ? "pagar" : "cobrar" }}
              </p>
              <p v-if="$route.params.idLinea" class="has-text-grey-light">
                Línea #{{ getLinea.id }}
              </p>
              <p v-if="$route.params.idDisposicion" class="has-text-grey-light">
                Disposición #{{ getDisposicionDetalle.folio_credito }}
              </p>
            </div>
          </div>
          <valid-select
            rules="required"
            label="Tipo de cuenta"
            placeholder="Selecciona una opción..."
            v-model="form.tipo_de_operacion_id"
            :expanded="true"
            :data="getCostosExtras"
            show="nombre"
            realValue="id"
          />
          <div class="border my-3" />
          <div v-if="form.tipo_de_operacion_id != null">
            <div class="columns is-multiline">
              <div class="column is-6">
                <valid-currency-input
                  v-if="concepto.tipo_pago.nombre == 'Monto'"
                  rules="currency|notcero"
                  label="Cantidad"
                  placeholder="Introduzca un valor..."
                  v-model="form.cantidad"
                  :expanded="true"
                />
                <valid-input
                  v-else
                  rules="notcero"
                  type="number"
                  addon
                  addon-icon="percentage"
                  :label="`Porcentaje sobre capital de ${form.cargo}`"
                  placeholder="Introduzca un valor..."
                  v-model="form.cantidad"
                  :expanded="true"
                />
              </div>
              <div class="column is-3">
                <valid-select
                  rules="required"
                  label="Moneda"
                  placeholder="Selecciona una opción..."
                  v-model="form.moneda_id"
                  :expanded="true"
                  :data="getMonedas"
                  show="nombre"
                  realValue="id"
                  :disabled="concepto.tipo_pago.nombre == 'Monto'"
                />
              </div>
              <div class="column is-3 flex mt-6">
                <b-checkbox v-model="form.iva" size="is-large">IVA</b-checkbox>
              </div>
              <div v-if="!getLinea.grupo_credito_id" class="column is-6">
                <valid-select
                  rules="required"
                  label="Persona"
                  placeholder="Selecciona una persona..."
                  v-model="form.cliente"
                  :expanded="true"
                  :data="clientes"
                  show="relacionado.nombre_completo"
                  realValue="relacionado.id"
                />
              </div>
              <div class="column is-6">
                <valid-datepicker
                  rules="required"
                  label="Fecha de vencimiento"
                  placeholder="selecciona una fecha..."
                  v-model="form.fecha_vencimiento"
                  position="is-top-right"
                />
              </div>
              <div v-if="getLinea.grupo_credito_id" class="column is-6" />
              <div class="column is-4">
                <b-radio :disabled="!!$route.params.idLinea || !!$route.params.idDisposicion" v-model="form.cargo" native-value="linea">
                  Cargo a línea
                </b-radio>
              </div>
              <div class="column is-4">
                <b-radio :disabled="!!$route.params.idLinea || !!$route.params.idDisposicion" v-model="form.cargo" native-value="credito">
                  Cargo a crédito
                </b-radio>
              </div>
              <div class="column is-4">
                <b-radio :disabled="!!$route.params.idLinea || !!$route.params.idDisposicion" v-model="form.cargo" native-value="garantia">
                  Cargo a garantía
                </b-radio>
              </div>
              <div class="column is-12">
                <valid-select
                  disabled
                  v-if="form.cargo == 'linea'"
                  rules="required"
                  label="Línea de crédito"
                  placeholder="Selecciona una línea..."
                  v-model="form.lineadecredito_id"
                  :expanded="true"
                  :data="lineas"
                  show="id"
                  realValue="id"
                />
                <valid-select
                  v-if="form.cargo == 'credito'"
                  rules="required"
                  label="Crédito"
                  placeholder="Selecciona un crédito de la línea..."
                  v-model="form.simulacion_id"
                  :expanded="true"
                  :data="disposiciones"
                  :show="
                    $route.params.idDisposicion
                      ? 'folio_credito'
                      : 'disposicion.folio_credito'
                  "
                  :realValue="
                    $route.params.idDisposicion ? 'id' : 'creditos_core_id'
                  "
                />
                <valid-select
                  v-if="form.cargo == 'garantia'"
                  rules="required"
                  label="Garantía"
                  placeholder="Selecciona una garantía de la línea..."
                  v-model="form.garantias_id"
                  :expanded="true"
                  :data="getGarantias"
                  show="garantia.id"
                  realValue="garantia.id"
                />
              </div>
              <div class="column is-12">
                <valid-input
                  rules="required"
                  label="Notas"
                  class="has-background-white-ter"
                  placeholder="Notas..."
                  v-model="form.notas"
                  type="textarea"
                  :expanded="true"
                />
              </div>
              <div class="column is-12 text-right">
                <b-dropdown
                  v-if="cargo != 'garantia' && !esFinanciada"
                  aria-role="list"
                  position="is-top-right"
                >
                  <template #trigger="{ active }">
                    <b-button
                      label="Agregar cuenta"
                      type="is-primary"
                      :icon-right="active ? 'chevron-up' : 'chevron-down'"
                    />
                    <!-- <b-icon :icon="active ? 'chevron-down' : 'chevron-right'" /> -->
                  </template>

                  <b-dropdown-item
                    @click="validate().then(res => financiarCuenta(res))"
                    aria-role="listitem"
                    class="text-left"
                  >
                    Financiar cuenta
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="validate().then(res => createCuenta(res))"
                    aria-role="listitem"
                    class="text-left"
                  >
                    Agregar sin financiar
                  </b-dropdown-item>
                </b-dropdown>
                <b-button
                  v-if="cargo == 'garantia' || esFinanciada"
                  label="Agregar cuenta"
                  type="is-primary"
                  @click="validate().then(res => createCuenta(res))"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </validation-observer>
    <router-view v-else />
  </div>
</template>

<script>
import BannerLeft from "@/components/banners/BannerLeft";
import ValidSelect from "@/components/form/ValidSelect";
import ValidCurrencyInput from "@/components/form/ValidCurrencyInput";
import ValidInput from "@/components/form/ValidInput";
import ValidDatepicker from "@/components/form/ValidDatepicker";
import { mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";

export default {
  name: "CuentaCobrarPagar",
  components: {
    BannerLeft,
    ValidSelect,
    ValidCurrencyInput,
    ValidInput,
    ValidDatepicker,
    ValidationObserver
  },
  data() {
    return {
      image: "/images/cuentas_cobrar_pagar.svg",
      form: {
        tipo_de_operacion_id: null,
        cantidad: null,
        iva: false,
        cliente: null,
        moneda_id: null,
        fecha_vencimiento: null,
        cargo: "linea",
        tipo: null,
        simulacion_id: null,
        lineadecredito_id: null,
        disposicion_id: null,
        garantias_id: null,
        notas: null
      }
    };
  },
  methods: {
    async financiarCuenta(res) {
      if (!res) return;
      let form = JSON.parse(JSON.stringify(this.form));
      form.fecha_vencimiento = this.$moment(form.fecha_vencimiento).format(
        "YYYY-MM-DD"
      );
      if (this.$route.params.iDLinea) {
        await this.$store.dispatch("lineascredito/setCuenta", form);
      } else {
        await this.$store.dispatch("disposicion/setCuenta", form);
      }
      this.$router.push({
        name: "financiar_cuenta"
      });
    },
    createCuenta(res) {
      if (!res) return;
      let form = JSON.parse(JSON.stringify(this.form));
      form.fecha_vencimiento = this.$moment(form.fecha_vencimiento).format(
        "YYYY-MM-DD"
      );
      this.$store.dispatch("lineascredito/crearCuenta", form).then(() => {
        this.$router.push({
          name: this.$route.params.idLinea ? "detalle_linea" : "disposicion"
        });
      });
    }
  },
  watch: {
    cargo(value) {
      value === "linea"
        ? (this.form.lineadecredito_id = this.getLinea.id)
        : (this.form.lineadecredito_id = null);
    },
    concepto() {
      if (this.concepto.tipo_pago.nombre == "Monto") {
        this.form.moneda_id = this.getLinea.moneda_id;
      } else {
        this.form.moneda_id = null;
      }
    }
  },
  computed: {
    ...mapGetters("lineascredito", [
      "getLinea",
      "getDisposiciones",
      "getGarantias"
    ]),
    ...mapGetters("disposicion", ["getDisposicionDetalle"]),
    ...mapGetters("catalogos", ["getCostosExtras", "getMonedas"]),
    esFinanciada() {
      if (
        this.getDisposicionDetalle && (
        this.getDisposicionDetalle.origen_credito_core ||
        this.getDisposicionDetalle.origen_linea_credito ||
        this.getDisposicionDetalle.origen_garantia)
      ) {
        return true;
      }

      return false;
    },
    ruta() {
      let ruta;
      if (this.$route.params.idLinea) {
        ruta = "cuenta_linea";
      } else if (this.$route.params.idDisposicion) {
        ruta = "cuenta_disposicion";
      }
      return ruta;
    },
    cargo() {
      return this.form.cargo;
    },
    concepto() {
      return this.getCostosExtras.find(
        x => x.id == this.form.tipo_de_operacion_id
      );
    },
    clientes() {
      return this.getLinea.relaciones.filter(x => x.rol.nombre == "Cliente");
    },
    lineas() {
      return [
        {
          id:
            this.$route.params.idLinea ||
            this.getDisposicionDetalle.linea_credito[0].id
        }
      ];
    },
    disposiciones() {
      return this.getDisposiciones
        ? this.getDisposiciones.disposiciones
        : [this.getDisposicionDetalle];
    }
  },
  async mounted() {
    if (this.getCostosExtras.length == 0) {
      this.$store.dispatch("catalogos/getCostosExtras");
    }
    if (this.getMonedas.length == 0) {
      this.$store.dispatch("catalogos/getMonedas");
    }
    if (this.$route.params.idDisposicion) {
      await this.$store.dispatch(
        "disposicion/getDisposicionDetalle",
        this.$route.params.idDisposicion
      );
    }
    if (this.$route.params.idLinea) {
      this.$store.dispatch("lineascredito/getDisposiciones", {
        linea_id: this.$route.params.idLinea
      });
    }
    await this.$store.dispatch(
      "lineascredito/getLineaById",
      this.$route.params.idLinea
        ? this.$route.params.idLinea
        : this.getDisposicionDetalle.linea_credito[0].id
    );
    this.$store.dispatch(
      "lineascredito/getGarantias",
      {
        linea_id: this.$route.params.idLinea
          ? this.$route.params.idLinea
          : this.getDisposicionDetalle.linea_credito[0].id
      }
    );
    this.form.cliente = this.getLinea.relaciones.filter(
      x => x.rol.nombre == "Cliente"
    )[0]?.relacionado.id;
    this.form.tipo = this.getLinea.pasiva ? "por_pagar" : "por_cobrar";
    this.form.cargo = this.$route.params.idLinea ? "linea" : "credito";
    if (this.$route.params.idLinea) {
      this.form.lineadecredito_id = this.$route.params.idLinea;
    }
  }
};
</script>
